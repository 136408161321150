// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-for-educators-tsx": () => import("./../src/pages/for-educators.tsx" /* webpackChunkName: "component---src-pages-for-educators-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-site-map-tsx": () => import("./../src/pages/siteMap.tsx" /* webpackChunkName: "component---src-pages-site-map-tsx" */),
  "component---src-pages-students-tsx": () => import("./../src/pages/students.tsx" /* webpackChunkName: "component---src-pages-students-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

